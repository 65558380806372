<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import type { Meter } from '~/types/meter';

// Composables
const { showToast } = toastStore();
const { t } = useI18n();

// Props and emits
const modal = defineModel<boolean>();
const props = defineProps<{
  meterId?: number;
}>();

// Form
const schema = yup.object({
  area: yup.object({
    name: yup.string().required(t('meter.form.the_name_is_required')),
  }),
});
const { handleSubmit, setValues, values } = useForm<Meter>({
  validationSchema: schema,
});

// Methods
const submit = handleSubmit(async () => {
  try {
    if (props.meterId) {
      await meterStore().updateMeter({ id: props.meterId, form: values });
      modal.value = false;
      showToast({ type: 'success', title: t('global.success'), message: t('meter.meter_updated') });
    }
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
});

// Watchers
watch(
  () => modal.value,
  () => {
    if (modal.value === true && props.meterId) {
      const meter = meterStore().getMeter(props.meterId);
      if (meter) {
        setValues({
          area: {
            id: meter.area.id,
            name: meter.area.name,
          },
        });
      }
    }
  },
);
</script>

<template>
  <ui-modal v-model="modal" width="600" :title="$t('meter.edit_meter')">
    <ui-form-input-text name="area.name" :placeholder="$t('meter.compressor')" :label="$t('meter.name')" required />

    <template #footer>
      <ui-button @click="submit">
        {{ meterId ? $t('meter.update_meter') : $t('meter.create_meter') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
