<script lang="ts" setup>
import { useMeterTable } from '../../composables/meterTable';
import MeterManageModal from '../../components/modals/MeterManageModal.vue';
import type { Meter } from '~/types/meter';

// Components

const { headers, actions } = useMeterTable();

// Data
const modal = ref(false);
const meterId = ref<number | undefined>(undefined);

// Computed
const machines = computed(() => meterStore().getMeters);

// Methods
const openEditModal = (item: Meter) => {
  meterId.value = item.id;
  modal.value = true;
};
</script>

<template>
  <ui-card :title="$t('meter.your_meters_and_clamps')">
    <ui-data-table :headers="headers" :items="machines">
      <template #item-name="{ item }">
        {{ item.area.name }}
      </template>
      <template #item-area="{ item }">
        {{ item.area.parent?.name ?? '--' }}
      </template>
      <template #item-energy="{ item }">
        <ui-badge v-if="item.meter_type.energy_type === 'Electricity'" color="green"> Elec </ui-badge>
        <ui-badge v-else-if="item.meter_type.energy_type === 'Gas'" color="orange"> Gaz </ui-badge>
      </template>
      <template #item-type="{ item }">
        <ui-badge v-if="item.meter_type.id === 5" color="purple">
          {{ $t('machine.clamp') }}
        </ui-badge>
        <ui-badge v-else-if="item.meter_type.id === 6" color="purple">
          {{ $t('machine.clamp_sum') }}
        </ui-badge>
        <ui-badge v-else-if="item.meter_type.id === 1" color="gray">
          {{ $t('machine.enedis') }}
        </ui-badge>
        <ui-badge v-else-if="item.meter_type.id === 2" color="yellow">
          {{ $t('machine.GRDF') }}
        </ui-badge>
        <ui-badge v-else color="purple">
          {{ $t('machine.other') }}
        </ui-badge>
      </template>
      <template #item-action="{ item }">
        <ui-data-table-actions :items="actions" @click-item="openEditModal(item)" />
      </template>
    </ui-data-table>
  </ui-card>

  <!-- Manage modal -->
  <meter-manage-modal v-model="modal" :meter-id="meterId" />
</template>
