import { useI18n } from 'vue-i18n';
import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useMeterTable = () => {
  // COmposables
  const { t } = useI18n();

  const headers: Header[] = [
    {
      label: t('machine.header.name'),
      key: 'name',
    },
    {
      label: t('machine.header.area'),
      key: 'area',
    },
    {
      label: t('machine.header.energy'),
      key: 'energy',
    },
    {
      label: t('machine.header.type'),
      key: 'type',
    },
    {
      label: '',
      key: 'action',
    },
  ];

  const actions: TableAction[] = [
    {
      key: 'edit',
      icon: 'Pen',
      label: t('global.edit'),
    },
  ];

  return { headers, actions };
};
